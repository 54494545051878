import React, { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import brainxstewalternate from '../assets/images/brainxstewalternate.png';
import bow from '../assets/images/bow.gif';

const LoadingPage = () => {
    const [loading, setLoading] = useState(true);
    const [showContent, setShowContent] = useState(false);
    const [logoLoading, setLogoLoading] = useState(false);

    useEffect(() => {

        const loadingTimeout = setTimeout(() => {
            setLoading(false);
            setShowContent(true);
        }, 2000);


        const logoTimeout = setTimeout(() => {
            setLogoLoading(true);
        }, 5000);


        return () => {
            clearTimeout(loadingTimeout);
            clearTimeout(logoTimeout);
        };
    }, []);

    return (
        <div className="loading-page">
            <div className="logo-container">
                <div className="bow">
                    <img src={bow} className="bow" />
                </div>
            </div>
        </div>
    );
}

export default LoadingPage;
