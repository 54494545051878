import React from "react";
import bgLanding from '../assets/images/bgLanding.jpeg';
import Tabs from "../components/Tabs";
import brainxstewalternate from '../assets/images/brainxstewalternate.png';
import bow from '../assets/images/bow.gif';
import pinkIpod from '../assets/images/pinkIpod.png';
import AudioPlayer from "../components/Audio";
import heartWings from '../assets/images/heartWings.png';

const Landing = () => {
    return (

        <div className="logo">
            <img className="logo-landing" src={brainxstewalternate} />
            <div className='ipod-container'>
            <img className='heartWings' src={heartWings} />
                <img src={pinkIpod} alt="iPod Image" className='ipod-image' />
                <div className='audio-thumbnail-container'>
                    <AudioPlayer />
                </div>
                <img className='heartWings2' src={heartWings} />

            </div>
            <div className="tabbed-interface">
                <Tabs />
            </div>
        </div>

    )
}

export default Landing;