import React from "react";
import github from '../assets/images/github.png';


const Code = () => {

    return (
        <div 
        className="box-container"
        style={{maxWidth: '168.5vh'}}
        >
            <div className="github">
                <a href="https://github.com/ashdooom" target="_blank" rel="noopener noreferrer">
                    <img className="github-image" src={github} alt="GitHub Logo" />
                </a>  
            </div>

            <div className="scroll">
                <div className="code-about">
                    <h3 className="code-about">
                        <br />
                        <h1 className="objective"><i>☆Objective:☆</i></h1>
                        <br />
                        <div className="obj-descrip">
                            Proactive and results-oriented Full Stack Web Developer with a certification from Nucamp and a passion for building dynamic and responsive web applications.
                            <br />
                            Seeking to contribute to a team-oriented environment and further develop my technical skills while delivering high-quality work.
                            <br />
                        </div>
                        <h1 className="skills"><i>☆Skills:☆</i></h1>
                        <div className="obj-descrip">
                            <br />
                            ☆ Proficient in HTML, CSS, and JavaScript
                            <br />
                            ☆ Strong knowledge of front-end frameworks such as React.js
                            <br />
                            ☆ Experience with back-end development using Node.js and Express
                            <br />
                            ☆ Familiar with database management using MongoDB and MySQL
                            <br />
                            ☆ Skilled in RESTful API design and development
                            <br />
                            ☆ Understanding of version control using Git and GitHub
                            <br />
                            ☆ Familiarity with agile development methodologies
                            <br />
                            ☆ Excellent problem-solving abilities and attention to detail
                            <br />
                        </div>
                    </h3>


                    <div className="proj-title">
                        <h3 className="proj-title"><i>theBunnyRanch:</i></h3>
                    </div>

                    <div className="bun-description">
                        <h4
                            className="obj-descrip"
                            style={{ paddingLeft: '5vh' }}
                        >
                            <br />
                            Key Features
                            <br />
                            ☆ Developed using React Native for a seamless cross-platform experience.
                            <br />
                            ☆ Implemented Redux for state management, ensuring efficient and predictable state updates.
                            <br />
                            ☆ Implemented navigation with React Navigation to create a multi-page user interface.
                            <br />
                            ☆ Designed and implemented the UI, including product listings, a shopping cart, and a contact form.
                            <br />
                            ☆ Utilized the React Native Elements library for a consistent look and feel across the app.
                            <br />
                            ☆ Implemented a cart system allowing users to add and remove items seamlessly.
                            <br />
                            ☆ Added form validation for the contact form to ensure data integrity.
                            <br />
                            This app provides bunny owners with a one-stop shop for all their bunny needs, making it easy and convenient to find and purchase high-quality products.
                            <br />
                        </h4>

                    </div>
                    <div className="video">
                        <video className="video" autoPlay loop muted>
                            <source src={require('../assets/images/bunnyRanch.mp4')} type="video/mp4" />
                        </video>
                    </div>
                    <div className="gotDescrip">
                        <h3 className="gotDescrip">
                            <i>Game of Thrones Quote Generator:</i>
                        </h3>
                    </div>

                    <div className="obj-descrip">
                        <h4
                            className="obj-descrip"
                            style={{ paddingLeft: '3vh' }}
                        >
                            <br />
                            Key Features:
                            <br />
                            ☆ Developed a mobile app using React Native that provides users with a curated collection of memorable Game of Thrones quotes and allows them to generate random quotes at the press of a button.
                            <br />
                            ☆ Implemented a Favorites feature that enables users to add quotes to their Favorites list and view them in a separate screen.
                            <br />
                            ☆ Integrated Redux and Redux Toolkit for state management to handle adding and removing quotes from the Favorites list efficiently.
                            <br />
                            ☆ Used FontAwesome icons for a visually appealing and interactive user interface, with icons that change color when a quote is added to or removed from the Favorites list.
                            <br />
                            ☆ Ensured a smooth and responsive user experience with the implementation of asynchronous actions and a loading spinner during data retrieval.
                            <br />
                            ☆ Designed a custom drawer navigation with a unique user profile image that enhances the overall aesthetics of the app.
                            <br />
                        </h4>
                    </div>

                    <div className="video">
                        <video className="video" autoPlay loop muted>
                            <source src={require('../assets/images/gotGenerator.mp4')} type="video/mp4" />
                        </video>
                    </div>
                    <div className='proj-title'>
                        <h3 className="proj-title" 
                        style={{paddingLeft: '5vh'}}
                        >
                            brainxstew3.0 React Project
                        </h3>
                    </div>

                    <div className="obj-descrip">
                        <h4 className="obj-descrip"
                            style={{ paddingLeft: '3vh' }}>
                            Developed an interactive React-based website featuring a customizable audio player component, dynamic content display, and responsive design.
                            <br />
                            Key Features:
                            <br />
                            ☆ Custom Audio Player: Implemented a custom audio player component with play, pause, next track, and previous track functionalities.
                            <br />
                            ☆ Dynamic Content: Integrated dynamic content loading for tracks, allowing users to navigate through a playlist seamlessly.
                            <br />
                            ☆ Responsive Design: Ensured optimal user experience across various devices and screen sizes through responsive design principles and media queries.
                            <br />
                            ☆ Asset Management: Managed and optimized media assets such as images and audio files for efficient loading and performance.
                            <br />
                            ☆ Frontend: React.js, JSX, HTML5, CSS3
                            <br />
                            ☆ State Management: Utilized React hooks (useState, useRef, useEffect) for state management and lifecycle handling.
                            <br />
                            ☆ Asset Handling: Managed media assets (images, audio) using webpack and asset import statements.
                            <br />
                            ☆ Version Control: Employed Git for version control and collaboration, utilizing GitHub for repository management.
                            <br />
                        </h4>
                    </div>
                </div>
                <div className="brain">
                    <video className='brain' autoPlay loop muted>
                        <source src={require('../assets/images/brainStew.mp4')} type='video/mp4' />
                    </video>
                </div>
            </div>
        </div>
    )
}

export default Code;