import React, { useState, useEffect } from "react";
import './App.css';
import Landing from './Pages/LandingPage';
import Loading from './components/Loading';


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000); 

    return () => clearTimeout(timeout);
  }, []);

  

  return (
    <div className="App">
      {isLoading ? <Loading /> : <Landing />}
    </div>
  
  );
}

export default App;
