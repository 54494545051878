import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import piano from '../assets/images/piano.gif';
import { Button } from 'react-bootstrap';


const ModalComponent = ({ showModal, handleClose }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Dialog>
        <Modal.Body>
          <div className='modal' style={{ display: 'block', position: 'fixed', zIndex: '1050', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <h3>Thank you for your message! I'll get back to you as soon as possible ⋆⭒˚.⋆</h3>
            <img className='piano' src={piano} alt="Piano" />
            <Button className='modal-button' onClick={handleClose} variant="secondary">
           Close
            </Button>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
}

export default ModalComponent;
