import React, { useState } from 'react';
import Code from '../Pages/CodingPage';
import code from '../assets/images/code.png';
import artwork from '../assets/images/artwork.png';
import commissions from '../assets/images/commissions.png';
import guestbook1 from '../assets/images/guestbook1.png';
import about from '../assets/images/about.png';
import About from '../Pages/AboutPage';
import Commissions from '../Pages/CommPage';
import Artwork from '../Pages/ArtPage';
import Guestbook from '../Pages/GuestbookPage';

const Tabs = () => {
    const [activeTab, setActiveTab] = useState('Code');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (

        <div className='tabbed-interface'>
            <div className='tabs'>
                <button
                    className={`tab-button ${activeTab === 'Code' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Code')}
                >
                    <img className='code' src={code} />
                </button>
                <button
                    className={`tab-button ${activeTab === 'Artwork' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Artwork')}
                >
                    <img className='artwork' src={artwork} />
                </button>
                <button
                    className={`tab-button ${activeTab === 'Commissions' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Commissions')}
                >
                    <img className='commissions' src={commissions} />
                </button>
                <button
                    className={`tab-button ${activeTab === 'About' ? 'active' : ''}`}
                    onClick={() => handleTabClick('About')}
                >
                    <img className='about' src={about} />
                </button>
                    <button
                        className={`tab-button ${activeTab === 'Guestbook' ? 'active' : ''}`}
                        onClick={() => handleTabClick('Guestbook')}
                    >
                        <img className='guestbook' src={guestbook1} />
                    </button>
                <div className='tab-content'>
                    {activeTab === 'Code' && <Code />}
                    {activeTab === 'Artwork' && <Artwork />}
                    {activeTab === 'Commissions' && <Commissions />}
                    {activeTab === 'About' && <About />}
                    {activeTab === 'Guestbook' && <Guestbook />}
                </div>
            </div>
        </div>


    )
}

export default Tabs;
