  import React, { useState, useEffect } from "react";
import bubble from '../assets/images/bubble.gif';
import ballons from '../assets/images/ballons.gif';
import skulzz from '../assets/images/skulzz.jpeg';
import EmojiPicker from 'emoji-picker-react';

const Guestbook = () => {
    const [entries, setEntries] = useState([]);
    const [name, setName] = useState("");
    const [mood, setMood] = useState("");
    const [message, setMessage] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const ADMIN_PASSWORD = "Howpreciouslife1!"; // Replace with your secure password

    useEffect(() => {
        const storedEntries = JSON.parse(localStorage.getItem("guestbookEntries")) || [];
        setEntries(storedEntries);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const newEntry = { name, mood, message, date: new Date().toLocaleString() };
        const updatedEntries = [...entries, newEntry];
        setEntries(updatedEntries);
        localStorage.setItem("guestbookEntries", JSON.stringify(updatedEntries));
        setName("");
        setMood("");
        setMessage("");
    };

    const handleDelete = (index) => {
        const updatedEntries = entries.filter((entry, i) => i !== index);
        setEntries(updatedEntries);
        localStorage.setItem("guestbookEntries", JSON.stringify(updatedEntries));
    };

    const handleAdminLogin = () => {
        if (adminPassword === ADMIN_PASSWORD) {
            setIsAdmin(true);
            setAdminPassword("");
        } else {
            alert("don't even try it sucka");
        }
    };

    return (
        <div className="box-container">
            <div className="gbHeader">
                <h1 className="gbTitle">Come sign my guestbook!</h1>
            </div>
            <div className="bubbles">
                <img src={bubble} className="bubbles" />
            </div>
            <form className='gb' onSubmit={handleSubmit}>
                <label>name:</label>
                <br />
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="input"
                />
                <br />
                <label>mood:</label>
                <br />
                <input
                    type="text"
                    value={mood}
                    onChange={(e) => setMood(e.target.value)}
                    required
                    className="input"
                />
                <br />
                <label>msg:</label>
                <br />
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    className="input"
                />
                <br />
                <button className="submit-button" type="submit">
                    post msg
                </button>
                <div className="ballons">
                    <img src={ballons} className="ballons" />
                </div>
                <div>
                    {entries.map((entry, index) => (
                        <div key={index}>
                            <div className="entries">
                                <p><strong>{entry.name}</strong> <em>({entry.date})</em></p>
                                <p><i>mood: {entry.mood}</i></p>
                                <p>{entry.message}</p>
                                {isAdmin && (
                                    <button onClick={() => handleDelete(index)}>Delete</button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </form>
            {!isAdmin && (
                <div className="admin-login">
                    <h2>Admin Login</h2>
                    <input
                        type="password"
                        placeholder="Admin password"
                        value={adminPassword}
                        onChange={(e) => setAdminPassword(e.target.value)}
                    />
                    <button className='gbButton' onClick={handleAdminLogin}>Login</button>
                </div>
            )}
        </div>
    );
};

export default Guestbook;
