import React, { useEffect, useState } from "react";
import selfie2 from '../assets/images/selfie2.PNG';
import selfie3 from '../assets/images/selfie3.PNG';
import notepad from '../assets/images/notepad.png';
import kirby from '../assets/images/kirby.gif';
import starCloud from '../assets/images/starCloud.gif';
import ryan2 from '../assets/images/ryan2.PNG';
import bun from '../assets/images/bun.gif';
import butterfly from '../assets/images/butterfly.gif';
import pinkBg2 from '../assets/images/pinkBg2.jpeg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const About = () => {

    return (


        <div className='box-container'>
            <div className="about-container">
                <Container>
                    <Row>
                        <Col>
                            <div className='selfie'>
                                <img className='selfie' src={selfie2} alt="Selfie" />
                            </div>
                            <div className='bun'>
                                <img src={bun} className="bun" alt="Bun" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <div className='butterfly'>
                                <img src={butterfly} className="butterfly" alt="Butterfly" />
                            </div>
                            <div className="description">
                                <div className="notepad-container">
                                    <div className="notepad-bg">
                                        <img src={pinkBg2} className="notepad-bg" alt="Background" />
                                        <div className="notepad-text">
                                            <h2 className="notepad-text">
                                                hello! my name is ashley, im 27 living in CT, originally born and raised in LA. i live here with my perfect husband ryan and our little chihuahua bruiser. i absolutely adore our little family. thanks for taking the time to check out my website. this is brainxstew 4.0! this time i've included not only my personal page, but a few tabs for my art, commissions and most importantly: MY CODE!! this will be serving as my portfolio for both my art and coding projects. im hoping recruiters see this and think "wow! this girl can CODE!" i've been coding for about 3 years now and everyday i learn something new. thats the thing about coding and tech, theres ALWAYS something new to learn and you really never stop learning. im in this for the long haul. if you've been here since the inception of brainxstew1.0...thank you!! i appreciate every signature, every view, every piece of critcism. thank you. love you.
                                            </h2>
                                        </div>
                                        <div className='notepad'>
                                            <img className='notepad' src={notepad} alt="Notepad" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img className='selfie3' src={selfie3} alt="Selfie 3" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Col>
                    <Row>
                        <div className='couple'>
                            <img src={ryan2} className="couple" alt="Ryan" />
                        </div>
                        <div className='cloud'>
                            <img src={starCloud} className="cloud" alt="Star Cloud" />
                        </div>
                        <div className='kirby'>
                            <img className='kirby' src={kirby} alt="Kirby" />
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    );
};

export default About;
