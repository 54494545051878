import React, { useState } from 'react';
import axios from 'axios';
import ModalComponent from "../components/Modal";
import bowchain from '../assets/images/bowchain.gif';
import cake from '../assets/images/cake.gif';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { pink } from '@mui/material/colors';

const Commissions = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [commission, setCommission] = useState('');
    const [modalVisible, setModalVisible] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Form validation
            if (!name || !email || !phoneNumber || !message || !commission) {
                alert('hey! fill out all the fields so i can get back to you :)');
                return;
            }
    
            const postData = {
                name,
                email,
                phoneNumber,
                message,
                commission
            };
            const response = await axios.post('http://localhost:4000/commissions', postData);
            console.log(response.data);
            setName('');
            setEmail('');
            setPhoneNumber('');
            setMessage('');
            setError('');
            setModalVisible(true);
        } catch (error) {
            console.error('Error submitting form:', error.response ? error.response.data : error.message);
            setError('Error submitting form');
        }
    };
    

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const handleCommissionChange = (e) => {
        setCommission(e.target.value);
    };

    return (
        <div className="box-container">
            <div>
                <img className='bowchain' src={bowchain} alt="Bowchain" />
                <div>
                    <h1 className="commission-description">
                        Wanna get a website or art commission?
                        <br />
                        Bang my line!
                        <br />
                        Fill out this easy peasy form
                        <br />
                        and I will get back to you with pricing
                        <br />
                        and other important info!
                    </h1>
                </div>
                <div className="cake">
                    <img className="cake" src={cake} alt="Cake" />
                </div>
            </div>
            <div className="form-container">
                <form className="form" onSubmit={handleSubmit}>
                    <input className="input-field" type="text" name="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <input className="input-field" type="tel" name="phoneNumber" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    <input className="input-field" type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={commission}
                            onChange={handleCommissionChange}
                        >
                            <FormControlLabel
                                value="art"
                                control={<Radio
                                    sx={{
                                        color: pink[800],
                                        '&.Mui-checked': {
                                            color: pink[600],
                                        },
                                    }} />} label="Artwork" />
                            <FormControlLabel value="website" control={<Radio
                                sx={{
                                    color: pink[800],
                                    '&.Mui-checked': {
                                        color: pink[600],
                                    },
                                }}
                            />} label="Website/Coding Project" />
                        </RadioGroup>
                    </FormControl>
                    <textarea className="input-field" name="message" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                    <br />
                    <button type="submit" className="submit-button">Submit</button>
                </form>
            </div>
            {error && <p>{error}</p>}
            {modalVisible && <ModalComponent showModal={modalVisible} handleClose={handleCloseModal} />}
        </div>
    );
}

export default Commissions;
