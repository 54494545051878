import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faStepBackward,
    faPause,
    faPlay,
    faRedo,
    faStepForward,
} from '@fortawesome/free-solid-svg-icons';
import juicyCouture from '../assets/music/juicyCouture.mp3'
import handsUp from '../assets/music/handsUp.mp3';
import brainStew from '../assets/music/brainStew.mp3';
import cuntyRussian from '../assets/music/cuntyRussian.mp3';
import ferSure from '../assets/music/ferSure.mp3';
import pierceTheVeil from '../assets/music/pierceTheVeil.mp3';
import tatu from '../assets/music/tatu.mp3';
import getCrunk from '../assets/music/getCrunk.mp3';
import bitchBitesDog from '../assets/music/bitchBitesDog.mp3';
import bb from '../assets/music/bb.mp3'
import brokencyde from '../assets/musicArt/brokencyde.jpg';
import insomniac from '../assets/musicArt/insomniac.jpg';
import katya from '../assets/musicArt/katya.jpeg';
import medicDroid from '../assets/musicArt/medicDroid.jpeg';
import tatuArt from '../assets/musicArt/tatuArt.jpg';
import jaws from '../assets/musicArt/jaws.jpeg';
import ayesha from '../assets/musicArt/ayesha.png';
import barelyhuman from '../assets/musicArt/barelyhuman.jpg';
import shygirl from '../assets/musicArt/shygirl.jpeg';
import cecile from '../assets/musicArt/cecile.png';

const AudioPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [currentSongIndex, setCurrentSongIndex] = useState(0);

    const audioRef = useRef(null);

    const songs = [
        { id: 0,
            song: juicyCouture,
            art: ayesha,
            title: 'The First Lady of Juicy Couture by Ayesha Erotica'
        },
        {
            id: 1,
            song: handsUp,
            art: barelyhuman,
            title: 'Hands Up! by 6arelyhuman'
        },
        {
            id: 2,
            song: brainStew,
            art: insomniac,
            title: 'Brain Stew by Green Day',
        },
        {
            id: 3,
            song: cuntyRussian,
            art: katya,
            title: 'Мой мармеладный by Катя Лель',
        },
        {
            id: 4,
            song: bitchBitesDog,
            art: cecile,
            title: 'Bitch Bites Dog by Cecile Believe',
        },

        {
            id: 5,
            song: bb,
            art: shygirl,
            title: 'BB by Shygirl'
        },
        {
            id: 5,
            song: pierceTheVeil,
            art: jaws,
            title: 'Pass The Nirvana by Pierce The Veil',
        },
        {
            id: 6,
            song: tatu,
            art: tatuArt,
            title: 'All The Things She Said by t.A.T.u',
        },
        {
            id: 7,
            song: getCrunk,
            art: brokencyde,
            title: 'Get Crunk by BrokenCYDE',
        },
    ];

    useEffect(() => {
        const shuffledSongs = shuffleArray([...songs]);
        setCurrentSongIndex(0);
        audioRef.current.src = shuffledSongs[0].song;

        audioRef.current.play();
        setIsPlaying(true);
    }, []);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.src = songs[currentSongIndex].song;
            audioRef.current.play();
            setIsPlaying(true);
        }
    }, [currentSongIndex]);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleSeek = (e) => {
        const seekTime = e.target.value;
        audioRef.current.currentTime = seekTime;
        setCurrentTime(seekTime);
    };

    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const handleSongChange = (index) => {
        setCurrentSongIndex(index);
        setIsPlaying(false);
        setCurrentTime(0);
    };

    const handleRewind = () => {
        audioRef.current.currentTime = 0;
        setCurrentTime(0);
    };

    const handleNextSong = () => {
        handleSongChange((currentSongIndex + 1) % songs.length);
    };

    const handlePreviousSong = () => {
        const newIndex = (currentSongIndex - 1 + songs.length) % songs.length;
        handleSongChange(newIndex);
    };

    return (
        <div>
            <audio
                ref={audioRef}
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleNextSong}
                onLoadedMetadata={handleLoadedMetadata}
            />
 
            <div className='audio-thumbnail-container'>
                <img
                    className="audio-thumbnail"
                    src={songs[currentSongIndex].art}
                    alt={`Thumbnail for ${songs[currentSongIndex].song}`}
                />
            </div>
            <div className="audio-controls">
                <button onClick={handlePreviousSong}>
                    <FontAwesomeIcon icon={faStepBackward} />
                </button>
                <button onClick={handleRewind}>
                    <FontAwesomeIcon icon={faRedo} />
                </button>
                <button onClick={togglePlayPause}>
                    {isPlaying ? (
                        <FontAwesomeIcon icon={faPause} />
                    ) : (
                        <FontAwesomeIcon icon={faPlay} />
                    )}
                </button>
                <button onClick={handleNextSong}>
                    <FontAwesomeIcon icon={faStepForward} />
                </button>
            </div>
            <div className="marquee">
                <div className="marquee-content">
                    {songs[currentSongIndex].title}
                </div>
            </div>
        </div>
    );
};

export default AudioPlayer;
