import React, { useState } from "react";
import amy from '../assets/images/amy.png';
import art from '../assets/images/art.png';
import art2 from '../assets/images/art2.png';
import bruiser from '../assets/images/bruiser.png';
import jolyne from '../assets/images/jolyne.png';
import meet from '../assets/images/meet.png';
import morrigan from '../assets/images/morrigan.png';
import peach from '../assets/images/peach.png';
import rouge from '../assets/images/rouge.png';
import ryan from '../assets/images/ryan.png';
import twinstar from '../assets/images/twinstar.gif';
import wingz from '../assets/images/wingz.gif';
import instagram from '../assets/images/instagram.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

const Artwork = () => {


    return (
        <div className="box-container">
            <div className="scroll">
                <div className="art-about">

                    <img src={wingz} className="wingz" alt="Wingz" />

                    <h2 className="art-descrip">
                        hi! thanks for checking out my artwork! feel free to send me a message under the commission tab ☆
                    </h2>
                    <div className="ig">
                        <a href="https://instagram.com/brainxstew.art">
                            <FontAwesomeIcon icon={faInstagram} size="2xl" color="#ff0672" beat />
                        </a>
                    </div>
                    <div className="twin">
                        <img src={twinstar} className="twin" alt="Twinstar" />
                    </div>
                </div>
                <div className="carousel-container">

                <div className="artDescriptions">
                    <img src={meet} className="carousel-img" alt="Meet BRAINxSTEW" />

                    <h3>Meet BRAINxSTEW</h3>


                    <img src={rouge} className="carousel-img" alt="Rouge the Bat" />

                    <h3>Rouge the Bat from Sonic</h3>


                    <img src={amy} className="carousel-img" alt="Amy Winehouse" />

                    <h3>Amy Winehouse 🌹</h3>


                    <img src={art} className="carousel-img" alt="Pink Haired Girl" />

                    <h3>Pink Haired Girl</h3>


                    <img src={art2} className="carousel-img" alt="Selfie" />

                    <h3>Selfie</h3>


                    <img src={peach} className="carousel-img" alt="Princess Peach" />

                    <h3>Princess Peach</h3>


                    <img src={morrigan} className="carousel-img" alt="Morrigan" />

                    <h3>Morrigan</h3>


                    <img src={bruiser} className="carousel-img" alt="My Dog, Bruiser" />

                    <h3>My Dog, Bruiser</h3>


                    <img src={ryan} className="carousel-img" alt="My Husband, Ryan" />

                    <h3>My Husband, Ryan</h3>


                    <img src={jolyne} className="carousel-img" alt="Jolyne" />

                    <h3 style={{ textAlign: "center" }}>Jolyne from JoJo's Bizarre Adventure</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Artwork;
